























import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import upwardRunningOrderClient, {
  ValidateRunningOrderShippingPayload,
} from '@/clients/upwardRunningOrderClient'
import store from '@/store'
import { componentState, stateIsLoaded } from '@/views/UpwardRunning/Orders/orders/lib/RunningOrderSM'
import StepIntro from '@/views/UpwardRunning/Orders/orders/steps/StepIntro.vue'
import StepSelectAddOnItems from '@/views/UpwardRunning/Orders/orders/steps/StepSelectAddOnItems.vue'
import StepReviewParticipantDetail from '@/views/UpwardRunning/Orders/orders/steps/StepReviewParticipantDetail.vue'
import StepShipping from '@/views/UpwardRunning/Orders/orders/steps/StepShipping.vue'
import StepReview from '@/views/UpwardRunning/Orders/orders/steps/StepReview.vue'
import StepConfirm from '@/views/UpwardRunning/Orders/orders/steps/StepConfirm.vue'
import { UROSStates, SizeUpdatedPayload } from '@/lib/support/store/orders/upwardRunningOrderState'
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import Loading from '@/elements/Loading.vue'
import AlertCloak, { AlertCloakTypes } from '@/components/AlertCloak.vue'

export default defineComponent({
  name: 'OrderRunning',
  components: {
    Loading,
    StepIntro,
    AlertCloak,
    StepReviewParticipantDetail,
    StepSelectAddOnItems,
    StepReview,
    StepShipping,
    StepConfirm,
  },
  setup(props, ctx) {
    const itemList = ref<RunningOrderProductInfo[]>([])
    const challenge = computed(() => store.getters.upwardRunning.currentChallenge)
    const state = computed(() => store.getters.upwardRunningOrders.uros)
    const readyToGo = ref(false)
    //** are we already resetting the order?
    const resetting = ref(false)
    const orderConfirmed = ref(false)
    /**
     * Go to the beginning, set the step to first step.
     */
    async function resetOrder() {
      if (!resetting.value) {
        resetting.value = true
        orderConfirmed.value = false
        await store.commit.upwardRunningOrders.setCurrentStep({
          currentStep: UROSStates.PRE_STEP,
        })
        Vue.nextTick(async () => {
          try {
            itemList.value =
              (await upwardRunningOrderClient.getRunningOrderItems(
                challenge.value?.upwardChallengeID ?? ''
              )) ?? []

            await store.dispatch.upwardRunningOrders.resetOrder({
              challenge: challenge.value.upwardChallengeID ?? '',
            })
          } finally {
            readyToGo.value = true
            resetting.value = false
          }
        })
      }
    }

    /**
     * Route change watch for back button. stepping before the first state is invalid, and league change on the url will reload the entire order
     */
    watch(
      () => ctx.root.$route,
      async () => {
        if (
          orderConfirmed.value ||
          parseInt(ctx.root.$route.params?.step ?? UROSStates.PRE_STEP) === UROSStates.PRE_STEP ||
          !stateIsLoaded(state.value)
        ) {
          await resetOrder()
        }

        if (
          // we may need to load state
          stateIsLoaded(state.value) &&
          // we want to make sure we aren't getting the same step we are on.
          state.value.currentStep != parseInt(ctx.root.$route.params?.step ?? UROSStates.PRE_STEP)
        ) {
          const challengeid = ctx.root.$route.params?.id ?? ''

          // make sure league id on URL matches our league state.
          if (challengeid != state.value?.template.upwardChallengeID) {
            await resetOrder()
          } else {
            store.commit.upwardRunningOrders.setCurrentStep({
              currentStep: parseInt(ctx.root.$route.params?.step ?? UROSStates.PRE_STEP),
            })
          }
        }
      },
      { immediate: true }
    )

    watch(
      () => challenge.value,
      async () => {
        if (challenge.value && challenge.value.upwardChallengeID) {
          await resetOrder()
        }
      },
      { immediate: true }
    )

    function setCurrentStep(payload: { currentProgram: string; currentStep: UROSStates }) {
      if (payload.currentStep.toString() != ctx.root.$route.params?.step ?? UROSStates.PRE_STEP) {
        ctx.root.$router.push({
          params: { ...ctx.root.$route.params, step: payload.currentStep.toString() },
        })
      }
      store.commit.upwardRunningOrders.setCurrentStep({ currentStep: payload.currentStep })
    }

    async function sizingUpdate(payload: SizeUpdatedPayload) {
      const updatedPayload = cloneDeep({
        ...payload,
        upwardChallengeID: challenge.value.upwardChallengeID ?? '',
        challengeID: challenge.value.id ?? 0,
      })

      console.log('TNT calling sizingUpdate with payload:', updatedPayload)

      await store.dispatch.upwardRunningOrders.sizingUpdate(updatedPayload)
    }

    async function validate(payload: ValidateRunningOrderShippingPayload) {
      const updatedPayload = cloneDeep({
        ...payload,
        upwardChallengeID: challenge.value.upwardChallengeID ?? '',
        challengeID: challenge.value.id,
      })

      await store.dispatch.upwardRunningOrders.validateRunningOrder(updatedPayload)
    }

    async function confirmOrder(payload: ValidateRunningOrderShippingPayload) {
      const updatedPayload = cloneDeep({
        ...payload,
        upwardChallengeID: challenge.value.upwardChallengeID ?? '',
        challengeID: challenge.value.id,
      })

      try {
        await store.dispatch.upwardRunningOrders.confirmOrder(updatedPayload)
        orderConfirmed.value = true
      } catch (e) {
        throw e
      }
    }

    function commitSizing(payload: any) {
      store.commit.upwardRunningOrders.setProductSize(payload)
    }

    onMounted(() => (readyToGo.value = false))
    // reset order on navigate away
    onUnmounted(() => {
      store.commit.upwardRunningOrders.eraseState()
    })
    const currentComponent = computed(() => componentState.find((x) => x.step == state.value.currentStep))

    return {
      itemList,
      challenge,
      currentComponent,
      state,
      commitSizing,
      setCurrentStep,
      sizingUpdate,
      validate,
      confirmOrder,
      readyToGo,
      alertTypes: [AlertCloakTypes.SALES_TAX],
    }
  },
})
