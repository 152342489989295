





















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { UpwardRunningOrderState } from '@/lib/support/store/orders/upwardRunningOrderState'
import currency from '@/filters/currency'
import { getOrderProductTotal } from '@/lib/support/models/GeneratedTypes/xOrders/xRunningOrderExt'

export default defineComponent({
  name: 'OrderPriceBreakdown',
  components: {},
  methods: { currency },
  props: {
    state: { type: Object as PropType<UpwardRunningOrderState>, required: true },
  },

  setup(props) {
    return {
      currentOrder: computed(() => props.state.template),
      totalOrderedProductAmount: computed(() => getOrderProductTotal(props.state.template)),
    }
  },
})
