






































































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { stateTransitions } from '@/views/UpwardRunning/Orders/orders/lib/RunningOrderSM'
import { UpwardRunningOrderState, UROSStates } from '@/lib/support/store/orders/upwardRunningOrderState'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import OrderListSection from '@/components/UpwardRunning/Orders/OrderListSection.vue'
import OrderPriceBreakdown from '@/views/UpwardRunning/Orders/components/OrderPriceBreakdown.vue'

import ShippingAddressSection from '@/components/ShippingAddressSection.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { getEmptyxShipToBase } from '@/lib/support/models/GeneratedTypes/xOrders/xShipToBase'
import currency from '@/filters/currency'
import Loading from '@/elements/Loading.vue'
import { ValidateRunningOrderShippingPayload } from '@/clients/upwardRunningOrderClient'
import SectionHeader from '@/components/SectionHeader.vue'

export default defineComponent({
  methods: { currency },
  components: {
    OrderPriceBreakdown,
    Loading,
    BackButton,
    OrderListSection,
    ShippingAddressSection,
    SectionHeader,
    InputLabel,
  },
  props: {
    state: { type: Object as PropType<UpwardRunningOrderState>, required: true },
    itemList: { type: Array as PropType<RunningOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    const currentOrder = computed(() => props.state.template)
    const currentOrderNotes = computed(() => props.state.template.notes)
    const currentPurchaseOrder = computed(() => props.state.template.purchaseOrderNumber)
    const currentOrderProducts = computed(() => props.state.template.products)
    const currentOrderShipTo = computed(
      () => props.state.template.shippingInfo?.shipTo ?? getEmptyxShipToBase()
    )
    const showOrderDetails = ref(false)
    const eventPayload = computed(
      () =>
        ({
          accountNumber: props.state.template.accountNumber,
          notes: currentOrderNotes.value,
          processingInstructions: [],
          purchaseOrderNumber: currentOrder.value.purchaseOrderNumber,
          shippingInfo: { ...props.state.template.shippingInfo, shipTo: { ...currentOrderShipTo.value } },
          upwardChallengeID: props.state.template.upwardChallengeID,
        } as ValidateRunningOrderShippingPayload)
    )

    watch(
      () => props.state.validated,
      async () => {
        if (props.state.validated) {
          await ctx.emit('setCurrentStep', stateTransitions[UROSStates.REVIEW_ORDER].continue(props.state))
        }
      }
    )

    return {
      currentOrderShipTo,
      currentPurchaseOrder,
      currentOrder,
      currentOrderNotes,
      currentOrderProducts,
      showOrderDetails,
      continueClicked: async () => {
        await ctx.emit('confirmOrder', eventPayload.value)
      },
    }
  },
})
