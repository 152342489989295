

























































import { computed, defineComponent, PropType, ref, watch, onMounted } from '@vue/composition-api'
import SelectInput from '@/elements/SelectInput.vue'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import { getEmptyRunningOrderProductInfo } from '@/lib/support/models/RunnningOrderProductInfo/data'
import { cloneDeep, groupBy } from 'lodash'
import SizeComponent from '@/views/Programs/Orders/league/components/SizeComponent.vue'
import ColumnButton from '@/views/Programs/Orders/league/components/ColumnButton.vue'
import CheckboxInput from '@/elements/CheckboxInput.vue'
import { isSizeUnselected } from '@/models/Order/OrderProduct'
import {
  xRunningOrderDetailIndividual,
  xRunningOrderDetailParticipantProduct,
  getEmptyxRunningOrderDetailIndividual,
} from '@/models/UpwardRunning/Order/xRunningOrderExt'
import { productList2ProductOption } from '@/lib/support/models/ListInfo/ProductColorSizeInfo/data'
import store from '@/store'

/*
interface ItemSizeType {
  item: string
  size: string
}
*/

interface GroupedProducts {
  selectedProductID: string
  selectedSizeID: string
  products: xRunningOrderDetailParticipantProduct[]
}

export default defineComponent({
  name: 'SizeParticipant',
  components: { ColumnButton, SelectInput, SizeComponent, CheckboxInput },
  props: {
    value: { type: Object as PropType<xRunningOrderDetailIndividual>, required: true },
    itemList: { type: Array as PropType<RunningOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    //const item_size = ref<ItemSizeType[]>([])

    onMounted(async () => {
      await store.dispatch.sizeTypes.fetchAll(false) //get these cached up
    })

    /*

    function getItemSize(item: string) {
      const ix = item_size.value.findIndex((x) => x.item == item)
      if (ix >= 0) {
        return item_size.value[ix].size
      }
      return internalValue.value.products.find((x) => x.upwardProductID == item)?.typeSizeID ?? 'NONE'
    }

    function changeItemSize(item: string, size: string) {
      const ix = item_size.value.findIndex((x) => x.item == item)
      if (ix >= 0) {
        item_size.value.splice(ix, 1, { item, size })
      } else {
        item_size.value.push({ item, size })
      }
    }
    */

    const internalValue = ref(getEmptyxRunningOrderDetailIndividual())
    const filteredProducts = computed(() => internalValue.value.products.filter((x) => x.typeSizeID != 'N/A')) //cheese out for now
    const groupedProducts = ref<GroupedProducts[]>([])

    watch(
      () => props.value,
      () => {
        internalValue.value = cloneDeep(props.value)
        //internalValue.value.products.forEach((x) => changeItemSize(x.upwardProductID, x.typeSizeID))
        groupedProducts.value = []
        //add products that are standalone
        filteredProducts.value
          .filter((x) => !x.parentUpwardProductID)
          .forEach((prod) => {
            groupedProducts.value.push({
              selectedProductID: prod.upwardProductID,
              selectedSizeID: prod.typeSizeID ?? 'NONE',
              products: [prod],
            })
          })
        //find all products with matching parents
        const productsWithParents = groupBy(
          filteredProducts.value.filter((x) => x.parentUpwardProductID),
          'parentUpwardProductID'
        )

        Object.keys(productsWithParents).forEach((key) => {
          const selectedProd =
            productsWithParents[key].find((x) => x.typeSizeID != 'NONE') ?? productsWithParents[key][0]
          groupedProducts.value.push({
            selectedProductID: selectedProd.upwardProductID,
            selectedSizeID: selectedProd.typeSizeID ?? 'NONE',
            products: productsWithParents[key],
          })
        })
      },
      { immediate: true }
    )

    function updateSize(v: GroupedProducts) {
      if (v.selectedSizeID != 'NONE') {
        //dont send the update if no size is selected (ie: if missing size and male/female selected first)
        v.products.forEach((p) => {
          if (p.upwardProductID == v.selectedProductID) {
            console.log('TNT sizingUpdate emit 1', {
              participantID: internalValue.value.individualID,
              product: { ...p, typeSizeID: v.selectedSizeID, replaced: true },
            })
            ctx.emit('sizingUpdate', {
              participantID: internalValue.value.individualID,
              product: { ...p, typeSizeID: v.selectedSizeID, replaced: true },
            })
          } else {
            console.log('TNT sizingUpdate emit 2', {
              participantID: internalValue.value.individualID,
              product: { ...p, typeSizeID: 'NONE', replaced: true },
            })
            //send an empty or no-size for everything but the selected product.
            ctx.emit('sizingUpdate', {
              participantID: internalValue.value.individualID,
              product: { ...p, typeSizeID: 'NONE', replaced: true },
            })
          }
        })
      }
    }

    /*
    function updateSize(v: xRunningOrderDetailParticipantProduct, size: string) {
      changeItemSize(v.upwardProductID, size)

      ctx.emit('sizingUpdate', {
        participantID: internalValue.value.individualID,
        product: { ...v, typeSizeID: size }, //,
      })
    }

    function click(v: xRunningOrderDetailParticipantProduct) {
      const size = item_size.value?.find((x) => x.item == v.upwardProductID)?.size ?? 'NONE'

      ctx.emit('sizingClick', {
        participantID: internalValue.value.individualID,
        product: { ...v, typeSizeID: size },
      })
    }
    */

    const sizesForItem = (itemID: string) =>
      productList2ProductOption(findProduct(itemID).productColorSizes ?? [])

    /**
     * Quick way to get a product by ID from the product list
     */
    function findProduct(x: string) {
      return props.itemList.find((y) => y.upwardProductID == x) ?? getEmptyRunningOrderProductInfo()
    }

    return {
      invalid: (x: GroupedProducts) => isSizeUnselected(x.selectedSizeID),
      internalValue,
      updateSize,
      //item_size,
      //click,
      filteredProducts,
      sizesForItem,
      groupedProducts,
    }
  },
})
