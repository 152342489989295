import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'

export function getEmptyRunningOrderProductInfo() {
  return {
    parentUpwardProductID: '',
    parentProductID: 0,
    allowExchange: false,
    maximumExchangeQuantity: 0,
    maximumOrderQuantity: 0,
    allowManualOrder: false,
    typeLeagueID: '',
    gpItemCategory: '',
    gpProgramAbbreviation: '',
    intendedForGender: '',
    includeInactives: false,
    id: 1,
    upwardProductID: '',
    typeProgramID: '',
    typeProductGroupID: '',
    registrationLabel: '',
    cost: 0,
    sortOrder: 0,
    productColorSizes: [],
    cmsProductName: '',
    cmsProductDescription: '',
    cmsProductImageUrls: [],
    cmsUnitDescription: '',
    cmsSizingGuideUrl: '',
    digitalFormatAvailable: '',
  } as RunningOrderProductInfo
}
