











































import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { stateTransitions } from '@/views/UpwardRunning/Orders/orders/lib/RunningOrderSM'
import { UpwardRunningOrderState, UROSStates } from '@/lib/support/store/orders/upwardRunningOrderState'
import MemoInput from '@/elements/MemoInput.vue'
import TextInput from '@/elements/TextInput.vue'
import ShippingAddressSection from '@/components/ShippingAddressSection.vue'
import { getEmptyxShipToBase } from '@/lib/support/models/GeneratedTypes/xOrders/xShipToBase'
import lodash, { cloneDeep } from 'lodash'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import { ValidateRunningOrderShippingPayload } from '@/clients/upwardRunningOrderClient'
import { xShipToBase } from '@/GeneratedTypes/xOrder/xShipToBase'
import VerifyingNotice from '@/views/Programs/Orders/league/steps/vues/VerifyingNotice.vue'

export default defineComponent({
  components: { BackButton, MemoInput, TextInput, ShippingAddressSection, VerifyingNotice },
  props: {
    state: { type: Object as PropType<UpwardRunningOrderState>, required: true },
  },
  setup(props, ctx) {
    const currentOrderNotes = ref('')
    const purchaseOrderNumber = ref('')
    const shipTo = ref(getEmptyxShipToBase())
    const eventPayload = computed(
      () =>
        ({
          accountNumber: props.state.template.accountNumber,
          notes: currentOrderNotes.value,
          processingInstructions: [],
          purchaseOrderNumber: purchaseOrderNumber.value,
          shippingInfo: { ...props.state.template.shippingInfo, shipTo: { ...shipTo.value } },
          upwardChallengeID: props.state.template.upwardChallengeID,
        } as ValidateRunningOrderShippingPayload)
    )

    watch(
      () => props.state.validated,
      async () => {
        if (props.state.validated) {
          await ctx.emit('setCurrentStep', stateTransitions[UROSStates.REVIEW_SHIPPING].continue(props.state))
        }
      }
    )

    watch(
      () => props.state,
      () => {
        if (currentOrderNotes.value !== props.state.template.notes) {
          currentOrderNotes.value = props.state.template.notes ?? ''
        }
        if (purchaseOrderNumber.value !== props.state.template.purchaseOrderNumber) {
          purchaseOrderNumber.value = props.state.template.purchaseOrderNumber ?? ''
        }
        if (!lodash.isEqual(shipTo.value, props.state.template.shippingInfo?.shipTo)) {
          shipTo.value = cloneDeep(props.state.template.shippingInfo?.shipTo) ?? getEmptyxShipToBase()
        }
      },
      { immediate: true, deep: true }
    )

    function shipToChanged(o: xShipToBase) {
      shipTo.value = cloneDeep(o)
    }

    return {
      shipToChanged,
      currentOrderNotes,
      purchaseOrderNumber,
      shipTo,
      continueClicked: async () => {
        await ctx.emit('validateOrder', eventPayload.value)
      },
    }
  },
})
