





























import { computed, defineComponent } from '@vue/composition-api'
import currency from '@/filters/currency'
import store from '@/store'
import { xRunningOrderDetailAddonProduct } from '@/models/UpwardRunning/Order/xRunningOrderExt'
import { OrderProduct } from '@/models/Order/OrderProduct'
import { OrderProductSize } from '@/models/Order/OrderProductSize'
import { cloneDeep } from 'lodash'
import { filterNA } from '@/models/Order/OrderProduct'

interface AddOnCartProduct extends xRunningOrderDetailAddonProduct {
  cost: number
  name: string
  color: string
  size: string
  pendingProduct: xRunningOrderDetailAddonProduct
}

export default defineComponent({
  name: 'AddOnCart',
  components: {},
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: { type: String, default: '', required: false },
  },
  setup(props, ctx) {
    const pendingItems = computed(() =>
      cloneDeep(store.getters.upwardRunningOrders.uros.pendingAddOnItems).sort((a, b) =>
        a.id > b.id ? 1 : -1
      )
    )

    const products = computed(() => {
      // merge pending items with information needed for display (like cmsProductName, cost, etc)
      return (
        pendingItems.value.map((p) => {
          const { base, colorSize } = catalogProduct(p.upwardProductID, p.typeSizeID, p.typeColorID)
          return addOnCartProduct(p, base, colorSize)
        }) ?? []
      )
    })

    function rowKey(product: AddOnCartProduct) {
      const p = product.pendingProduct
      return `${p.id}-${p.typeColorID}-${p.typeSizeID}`
    }

    function addOnCartProduct(
      pending: xRunningOrderDetailAddonProduct,
      base: OrderProduct | undefined,
      colorSize: OrderProductSize | undefined
    ) {
      return {
        pendingProduct: pending,
        cost: base && colorSize ? lineTotal(base.cost, colorSize.costOffset, pending.quantity) : 0,
        name: base?.cmsProductName,
        color: filterNA(colorSize?.colorDescription ?? ''),
        size: filterNA(colorSize?.sizeDescription ?? '')
          ? `(${filterNA(colorSize?.sizeDescription ?? '')})`
          : '',
      } as AddOnCartProduct
    }

    const productCatalog = computed(() => cloneDeep(store.getters.upwardRunningOrders.uros.template.products))
    function catalogProduct(id: string, size: string | null, color: string | null) {
      const base = productCatalog.value.find((pp) => pp.upwardProductID == id)
      const colorSize = base?.productColorSizes?.find((s) => s.typeSizeID == size && s.typeColorID == color)
      return { base, colorSize }
    }

    function lineTotal(cost: number, offset: number | undefined, quantity: number) {
      return (cost + (offset ?? 0)) * quantity
    }

    const total = computed(() => products.value.reduce((t, x) => t + x.cost, 0))

    function remove(p: AddOnCartProduct) {
      console.log('TNT - remove', props.readOnly)
      if (!props.readOnly) {
        console.log('TNT - remove - emitting', p.pendingProduct)
        ctx.emit('remove', p.pendingProduct)
      }
    }

    return { products, currency, remove, total, rowKey }
  },
})
