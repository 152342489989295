






































import { computed, defineComponent, onMounted, PropType, ref } from '@vue/composition-api'
import { stateTransitions } from '@/views/UpwardRunning/Orders/orders/lib/RunningOrderSM'
import { UpwardRunningOrderState, UROSStates } from '@/lib/support/store/orders/upwardRunningOrderState'
import ProductSelect from '@/components/Orders/ProductSelect.vue'
import ProductQuantitySelector from '@/views/Programs/Orders/vues/components/ProductQuantitySelector.vue'
import { UpwardSizeTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardSizeTypeID'
import AddOnCart from '@/components/UpwardRunning/Orders/AddOnCart.vue'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
import { PendingOrderAdditionalProductInfo } from '@/GeneratedTypes/ListInfo/PendingOrderAdditionalProductInfo'
import { sizeTypes } from '@/store/sizeTypes'
import store from '@/store'
import { xRunningOrderDetailAddonProduct } from '@/models/UpwardRunning/Order/xRunningOrderExt'
import { cloneDeep } from 'lodash'
//import lodash from 'lodash'

export default defineComponent({
  components: {
    ProductSelect,
    ProductQuantitySelector,
    AddOnCart,
    BackButton,
  },
  props: {
    state: { type: Object as PropType<UpwardRunningOrderState>, required: true },
  },
  setup(props, ctx) {
    const currentOrderProducts = computed(
      () =>
        //lodash.uniqBy(props.state.template.products, (x) => x.duplicateProductMatchingKey)
        props.state.template.products
    )
    const sizes = ref<UpwardSizeTypeID[]>([])

    onMounted(() => {
      sizes.value = cloneDeep(sizeTypes.state.items).sort((a: UpwardSizeTypeID, b: UpwardSizeTypeID) =>
        a.sortOrder == b.sortOrder ? 0 : a.sortOrder > b.sortOrder ? 1 : -1
      )
    })

    async function add(x: PendingOrderAdditionalProductInfo) {
      //convert to xRunningOrderDetailAddonProduct first
      const urProd = getURProd(x)
      if (urProd) {
        console.log('TNT - add product', urProd)
        await store.dispatch.upwardRunningOrders.addOrUpdate_AddOnProduct({
          upwId: upwId.value,
          product: urProd,
        })
      }
    }

    async function remove(x: PendingOrderAdditionalProductInfo) {
      //convert to xRunningOrderDetailAddonProduct first
      const urProd = getURProd(x)
      if (urProd) {
        await store.dispatch.upwardRunningOrders.remove_AddOnProduct({
          upwId: upwId.value,
          product: urProd,
        })
      }
    }

    function getURProd(x: PendingOrderAdditionalProductInfo): xRunningOrderDetailAddonProduct | null {
      const prodMatch = props.state.template.products.find((f) => f.id == x.productID)
      if (prodMatch) {
        const urProd = {
          id: x.id,
          productID: x.productID,
          upwardProductID: prodMatch.upwardProductID,
          parentUpwardProductID: '', //hopefully we don't need this downstream, otherwise figure out where to get it
          typeColorID: x.typeColorID ?? 'N/A',
          typeSizeID: x.typeSizeID ?? 'N/A',
          quantity: x.quantity,
          cmsProductName: prodMatch.cmsProductName ?? '',
          cmsProductDescription: prodMatch.cmsProductDescription ?? '',
          cmsProductImageUrls: prodMatch.cmsProductImageUrls ?? [],
        }
        return urProd
      } else {
        return null
      }
    }

    const upwId = computed(() => store.getters.upwardRunning.currentChallenge.upwardChallengeID ?? '')

    return {
      currentOrderProducts,
      sizes,
      add,
      remove,
      continueClicked: () =>
        ctx.emit('setCurrentStep', stateTransitions[UROSStates.REVIEW_ADDON_ITEMS].continue()),
    }
  },
})
