import { UpwardRunningOrderState, UROSStates } from '@/lib/support/store/orders/upwardRunningOrderState'
import { cloneDeep } from 'lodash'
import { RuntimeException } from '@/lib/common/exceptions/RuntimeException'

class RunningOrderSMRuntimeException extends RuntimeException {
  name = 'Running Order SM Error'
}

export const stateTransitions = {
  [UROSStates.REVIEW_PARTICIPANT_DETAIL]: {
    continue: () => ({
      currentStep: UROSStates.REVIEW_ADDON_ITEMS,
    }),
  },
  [UROSStates.PRE_STEP]: {
    continue: (p?: UpwardRunningOrderState) => {
      if (showParticipants(p) && p) {
        return {
          currentStep: UROSStates.REVIEW_PARTICIPANT_DETAIL,
        }
      } else {
        return {
          currentStep: UROSStates.REVIEW_ADDON_ITEMS,
        }
      }
    },
  },

  [UROSStates.REVIEW_ADDON_ITEMS]: {
    continue: () => ({
      currentStep: UROSStates.REVIEW_SHIPPING,
    }),
  },
  [UROSStates.REVIEW_SHIPPING]: {
    continue: (p?: UpwardRunningOrderState) =>
      p?.validated
        ? {
            currentStep: UROSStates.REVIEW_ORDER,
          }
        : { currentStep: UROSStates.REVIEW_SHIPPING },
  },
  [UROSStates.REVIEW_ORDER]: {
    continue: (p?: UpwardRunningOrderState) =>
      p?.validated
        ? {
            currentStep: UROSStates.CONFIRM_ORDER,
          }
        : {
            currentStep: UROSStates.REVIEW_ORDER,
          },
  },

  [UROSStates.CONFIRM_ORDER]: {},
}

export const componentState = [
  {
    step: UROSStates.PRE_STEP,
    component: 'StepIntro',
    label: 'Intro',
  },
  {
    step: UROSStates.REVIEW_PARTICIPANT_DETAIL,
    component: 'StepReviewParticipantDetail',
    label: 'Review Participant Detail',
  },
  {
    step: UROSStates.REVIEW_ADDON_ITEMS,
    component: 'StepSelectAddOnItems',
    label: 'Review Add-On Items',
  },
  {
    step: UROSStates.REVIEW_SHIPPING,
    component: 'StepShipping',
    label: 'Review Shipping',
  },
  {
    step: UROSStates.REVIEW_ORDER,
    component: 'StepReview',
    label: 'Review Order',
  },
  {
    step: UROSStates.CONFIRM_ORDER,
    component: 'StepConfirm',
    label: 'Confirm Order',
  },
]

export function mergeState(oldState: UpwardRunningOrderState, newState: UpwardRunningOrderState) {
  return cloneDeep({ ...oldState, ...newState })
}

export const stateIsLoaded = (x: UpwardRunningOrderState) => x.orderStatus && x.orderStatus.typeProgramID

function showParticipants(p?: UpwardRunningOrderState) {
  if (!p || !p?.orderStatus) {
    throw new RunningOrderSMRuntimeException('Invalid state, missing an order count.')
  }

  return !p?.orderStatus.orderCount || p?.orderStatus.participants.length > 0
}
