






































import { computed, defineComponent, PropType } from '@vue/composition-api'
import {
  UpwardRunningOrderState,
  UROSStates,
  SizeUpdatedPayload,
} from '@/lib/support/store/orders/upwardRunningOrderState'
import { RunningOrderProductInfo } from '@/GeneratedTypes/ListInfo/RunningOrderProductInfo'
import SizeParticipant from '@/views/UpwardRunning/Orders/components/SizeParticipant.vue'
import { stateTransitions } from '@/views/UpwardRunning/Orders/orders/lib/RunningOrderSM'
import BackButton from '@/views/Programs/Orders/league/components/BackButton.vue'
export default defineComponent({
  name: 'StepReviewParticipantDetail',
  components: { SizeParticipant, BackButton },
  props: {
    state: { type: Object as PropType<UpwardRunningOrderState>, required: true },
    itemList: { type: Array as PropType<RunningOrderProductInfo[]>, required: true, default: () => [] },
  },
  setup(props, ctx) {
    function sizingUpdated(payload: SizeUpdatedPayload) {
      ctx.emit('sizingUpdate', payload)
    }

    const orderStatus = computed(() => props.state.orderStatus)

    const continueClicked = () =>
      ctx.emit('setCurrentStep', stateTransitions[UROSStates.REVIEW_PARTICIPANT_DETAIL].continue())

    function sizingClicked(payload: unknown) {
      ctx.emit('sizingClick', payload)
    }

    function isEven(row: number) {
      return row % 2 == 0
    }

    return {
      sizingUpdated,
      orderStatus,
      continueClicked,
      sizingClicked,
      isEven,
    }
  },
})
