


































import { computed, defineComponent, PropType } from '@vue/composition-api'
import Loading from '@/elements/Loading.vue'
import { UpwardRunningOrderState, UROSStates } from '@/lib/support/store/orders/upwardRunningOrderState'
import { stateIsLoaded, stateTransitions } from '@/views/UpwardRunning/Orders/orders/lib/RunningOrderSM'
import { PartnerChallenge } from '@/models/UpwardRunning/PartnerChallenge'

export default defineComponent({
  components: { Loading },
  props: {
    challenge: { type: Object as PropType<PartnerChallenge>, required: true },
    state: { type: Object as PropType<UpwardRunningOrderState>, required: true },
  },
  setup(props, ctx) {
    const stillLoading = computed(() => !stateIsLoaded(props.state))
    function continueClicked() {
      ctx.emit('setCurrentStep', stateTransitions[UROSStates.PRE_STEP].continue(props.state))
    }

    function isFirstOrder() {
      return props.state.orderStatus.orderCount == 0
    }

    function isAdditionalOrder() {
      return props.state.orderStatus.orderCount > 0
    }

    function hasNewParticipants() {
      return props.state.orderStatus.participants.length > 0
    }

    return {
      stillLoading,
      continueClicked,
      isFirstOrder: computed(isFirstOrder),
      isAdditionalOrder: computed(isAdditionalOrder),
      hasNewParticipants: computed(hasNewParticipants),
    }
  },
})
